import {
  CommonProps,
  EuiButton,
  EuiDescribedFormGroup,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiFormRow,
  EuiHorizontalRule,
  EuiLink,
  EuiPanel,
  EuiRadioGroup,
  EuiSpacer,
  EuiTextColor,
  EuiTitle,
  htmlIdGenerator,
} from "@elastic/eui";
import AddressAutoComplete from "components/AddressAutoComplete";
import { useAPIURL } from "hooks/useAPIURL";
import { FunctionComponent, useEffect, useState } from "react";
import { Controller, UseFieldArrayReturn, useFieldArray, useFormContext } from "react-hook-form";
import NumberFormat from "react-number-format";

import { FormInputText } from "../../../../components/react-hook-form/FormInput";
import { FormSelect } from "../../../../components/react-hook-form/SelectInput";
import CoveragePopover from "../components/CoveragePopover";
import { FormDataType } from "../components/FormDataTypes";
import { FormPicker } from "../components/FormPicker";
import IncludedCoverageField from "../components/IncludedCoverageField";
import RaterForm from "../components/RaterForm";
import { formsData } from "../homeowner/data/formsData";
import { LPQuoteInput } from "../types/Rater.types";

interface LocationContentProps extends CommonProps {
  tabItem: number;
  policyForms: UseFieldArrayReturn<LPQuoteInput, "forms", "id">["fields"];
  removePolicyForm: UseFieldArrayReturn<LPQuoteInput, "forms", "id">["remove"];
  appendPolicyForm: UseFieldArrayReturn<LPQuoteInput, "forms", "id">["append"];
  rcButtonClick?: () => void;
}

const LocationContent: FunctionComponent<LocationContentProps> = ({
  tabItem,
  policyForms,
  removePolicyForm,
  appendPolicyForm,
  rcButtonClick,
}) => {
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    watch,
    trigger,
  } = useFormContext<LPQuoteInput>();
  const {
    fields: forms,
    append: appendForm,
    remove: removeForm,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: `locations.${tabItem}.forms` as `locations.0.forms`, // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });
  useEffect(() => {
    const subscription = watch((value, { name, ...type }) => {
      if (name === `locations.${tabItem}.yearBuilt` || name === `effectiveDate`) {
        const yearBuilt = parseInt(getValues(`locations.${tabItem}.yearBuilt` as any));
        const effectiveDate = getValues(`effectiveDate`);
        const effectiveYear = parseInt(effectiveDate.substring(effectiveDate.length - 4));

        // console.log("Year Built, effective Year: ", yearBuilt, effectiveYear);
        // console.log("Year Built, effective Year: ", typeof yearBuilt, typeof effectiveYear);

        if (yearBuilt && yearBuilt >= effectiveYear - 20) {
          const newHomeForm = forms.findIndex((item) => item.name === "NHD");
          if (newHomeForm === -1) {
            appendForm({
              formID: htmlIdGenerator()(),
              name: "NHD",
              fields: undefined,
            });
          }
        } else {
          const newHomeForm = forms.findIndex((item) => item.name === "NHD");
          if (newHomeForm !== -1) {
            removeForm(newHomeForm);
          }
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, forms, appendForm, removeForm, getValues, tabItem]);
  const valuation = watch("locations.0.valuation");

  useEffect(() => {
    if (valuation === "IACV") {
      setValue("locations.0.RCpercentage", "60");
    }
  }, [valuation, setValue]);

  const liabilityForm = watch("locations.0.liabilityForm");
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
  const defaultURL = useAPIURL();
  let flyout;
  if (isFlyoutVisible) {
    flyout = (
      <EuiFlyout ownFocus onClose={() => setIsFlyoutVisible(false)} aria-labelledby="flyoutTitle">
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2 id="flyoutTitle">Additional Coverages</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          <FormPicker
            onCloseHandler={() => {
              setIsFlyoutVisible(false);
            }}
            append={(form) => {
              appendForm(form);
            }}
            appendPolicyForm={(form) => {
              appendPolicyForm(form);
            }}
            currentForms={[
              ...forms.map((form) => {
                return form.name;
              }),
              ...policyForms.map((form) => {
                return form.name;
              }),
            ]}
            formsData={formsData}
            liability={liabilityForm !== "None"}
            location={0}
            raterValues={getValues()}
          />
        </EuiFlyoutBody>
      </EuiFlyout>
    );
  }

  return (
    <>
      <EuiPanel hasBorder>
        <EuiDescribedFormGroup
          title={<h3>{"Property Information"}</h3>}
          description={"General information for the property"}
          className="wcicRaterDescribedFormGroup"
        >
          <EuiFormRow fullWidth>
            <Controller
              name={`locations.0.isInsuredLocation` as const}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <EuiRadioGroup
                  options={[
                    { id: "newAddress", label: "New Address" },
                    { id: "insuredAddress", label: "Insured Address" },
                  ]}
                  idSelected={value}
                  onChange={(id) => {
                    if (id === "newAddress") {
                      setValue(`locations.${tabItem}.streetAddress` as any, "");
                      setValue(`locations.${tabItem}.streetAddress2` as any, "");
                      setValue(`locations.${tabItem}.city` as any, "");
                      setValue(`locations.${tabItem}.state` as any, "");
                      setValue(`locations.${tabItem}.zip` as any, "");
                    } else if (id === "insuredAddress") {
                      setValue(`locations.${tabItem}.streetAddress` as any, getValues("streetAddress"));
                      setValue(`locations.${tabItem}.streetAddress2` as any, getValues("streetAddress2"));
                      setValue(`locations.${tabItem}.city` as any, getValues("city"));
                      setValue(`locations.${tabItem}.state` as any, getValues("state"));
                      setValue(`locations.${tabItem}.zip` as any, getValues("zip"));
                    }
                    trigger([
                      `locations.${tabItem}.streetAddress` as any,
                      `locations.${tabItem}.city` as any,
                      `locations.${tabItem}.state` as any,
                      `locations.${tabItem}.zip` as any,
                    ]);
                    onChange(id);
                  }}
                  name="property radio group"
                />
              )}
            />
          </EuiFormRow>

          <EuiFormRow
            fullWidth
            label="Street Address"
            isInvalid={!!errors.locations?.[0]?.streetAddress}
            error={errors.locations?.[0]?.streetAddress?.message}
          >
            <Controller
              name={`locations.${tabItem}.streetAddress` as any}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <AddressAutoComplete
                  query={value}
                  onBlur={onBlur}
                  placeholder={""}
                  autoComplete={"new-location-address-1"}
                  onQueryChange={(search) => {
                    onChange(search);
                  }}
                  onItemClick={(item) => {
                    onChange(item.data.street_line);
                    setValue(`locations.${tabItem}.city` as any, item.data.city, { shouldValidate: true });
                    setValue(`locations.${tabItem}.state` as any, item.data.state, { shouldValidate: true });
                    setValue(`locations.${tabItem}.zip` as any, item.data.zipcode, { shouldValidate: true });
                  }}
                />
              )}
            />
          </EuiFormRow>

          <EuiFormRow
            label="Street Address 2"
            isInvalid={!!errors.locations?.[0]?.streetAddress2}
            error={errors.locations?.[0]?.streetAddress2?.message}
          >
            <Controller
              name={`locations.${tabItem}.streetAddress2` as any}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <EuiFieldText
                  onChange={onChange}
                  onBlur={(event) => {
                    onBlur();
                  }}
                  value={value}
                  inputRef={ref}
                />
              )}
            />
          </EuiFormRow>

          <EuiFormRow
            fullWidth
            label="City"
            isInvalid={!!errors.locations?.[0]?.city}
            error={errors.locations?.[0]?.city?.message}
          >
            <FormInputText
              name={`locations.${tabItem}.city`}
              label={"City"}
              rules={{ required: true }}
              key={tabItem}
              isInvalid={!!errors.locations?.[0]?.city}
              autoComplete={"new-location-city"}
            />
          </EuiFormRow>

          <EuiFormRow fullWidth>
            <EuiFlexGroup>
              <EuiFlexItem grow={false} style={{ width: 50, minWidth: 50 }}>
                <EuiFormRow
                  label="State"
                  isInvalid={!!errors.locations?.[0]?.state?.message}
                  error={errors.locations?.[0]?.state?.message}
                >
                  <FormInputText
                    name={`locations.${tabItem}.state`}
                    label={"State"}
                    rules={{ required: true }}
                    maxLength={2}
                    isInvalid={!!errors.locations?.[0]?.state?.message}
                    autoComplete={"new-location-state"}
                  />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem grow={false} style={{ width: 100, minWidth: 100 }}>
                <EuiFormRow
                  label="Zip"
                  isInvalid={!!errors.locations?.[0]?.zip?.message}
                  error={errors.locations?.[0]?.zip?.message}
                >
                  <FormInputText
                    name={`locations.${tabItem}.zip`}
                    label={"Zip"}
                    rules={{ required: true }}
                    isInvalid={!!errors.locations?.[0]?.zip?.message}
                    autoComplete={"new-location-zip"}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFormRow>

          <EuiFormRow
            fullWidth
            label="Year Built"
            isInvalid={!!errors.locations?.[0]?.yearBuilt}
            error={errors.locations?.[0]?.yearBuilt?.message}
          >
            <FormInputText
              name={`locations.${tabItem}.yearBuilt`}
              label={"Year Built"}
              rules={{ required: true }}
              key={tabItem}
              isInvalid={!!errors.locations?.[0]?.yearBuilt}
            />
          </EuiFormRow>

          <EuiFormRow
            fullWidth
            label="Construction Type"
            isInvalid={!!errors.locations?.[0]?.constructionType}
            error={errors.locations?.[0]?.constructionType?.message}
          >
            <FormSelect
              name={`locations.${tabItem}.constructionType`}
              label={"Construction Type"}
              rules={{ required: true }}
              options={[
                { value: "F", text: "Frame" },
                { value: "M", text: "Masonry" },
              ]}
              key={tabItem}
              isInvalid={!!errors.locations?.[0]?.constructionType}
            />
          </EuiFormRow>

          <EuiFormRow
            fullWidth
            label="Protection Class"
            isInvalid={!!errors.locations?.[0]?.protectionClass}
            error={errors.locations?.[0]?.protectionClass?.message}
            labelAppend={
              <CoveragePopover title="Protection Class" description="What are protection classes?">
                <ul>
                  <li>
                    <strong>Highly Protected</strong> - Within 1,000 feet of a hydrant and within 5 miles of a paid fire
                    department
                  </li>
                  <EuiSpacer />
                  <li>
                    <strong>Protected</strong> - Within 1,000 feet of a hydrant and within 5 miles of a volunteer fire
                    department
                  </li>
                  <EuiSpacer />
                  <li>
                    <strong>Partially Protected </strong> - Not within 1,000 feet of a hydrant but within 5 miles of a
                    fire department
                  </li>
                  <EuiSpacer />
                  <li>
                    <strong>Unprotected </strong> - Not within 1,000 feet of a hydrant and not within 5 miles of a fire
                    department
                  </li>
                </ul>
              </CoveragePopover>
            }
          >
            <FormSelect
              name={`locations.${tabItem}.protectionClass`}
              label={"Protection Class"}
              rules={{ required: true }}
              options={[
                { value: "A", text: "Highly Protected" },
                { value: "B", text: "Protected" },
                { value: "C", text: "Partially Protected" },
                { value: "D", text: "Unprotected" },
              ]}
              key={tabItem}
              isInvalid={!!errors.locations?.[0]?.protectionClass}
            />
          </EuiFormRow>

          <EuiFormRow
            fullWidth
            label="Number of Families"
            isInvalid={!!errors.locations?.[0]?.numFamilies}
            error={errors.locations?.[0]?.numFamilies?.message}
          >
            <FormSelect
              name={`locations.${tabItem}.numFamilies`}
              label={"Number of Families"}
              rules={{ required: true }}
              options={[
                { value: 1, text: "1" },
                { value: 2, text: "2" },
                { value: 3, text: "3" },
                { value: 4, text: "4" },
              ]}
              key={tabItem}
              isInvalid={!!errors.locations?.[0]?.numFamilies}
            />
          </EuiFormRow>
        </EuiDescribedFormGroup>
        <EuiHorizontalRule />
        <EuiDescribedFormGroup
          title={<h3>{"Property Coverages"}</h3>}
          description={"General information for the property"}
          className="wcicRaterDescribedFormGroup"
        >
          <EuiFormRow
            fullWidth
            label="Perils"
            isInvalid={!!errors.locations?.[0]?.perils}
            error={errors.locations?.[0]?.perils?.message}
            labelAppend={
              <CoveragePopover title="Perils" description="What is perils?">
                <ul>
                  <li>
                    <EuiLink
                      onClick={() => {
                        window.open(`${defaultURL}/form/ML-1R/01-1987`, "_blank");
                      }}
                    >
                      ML-1R (Basic)
                    </EuiLink>
                  </li>
                  <li>
                    <EuiLink
                      onClick={() => {
                        window.open(`${defaultURL}/form/ML-2/01-1987`, "_blank");
                      }}
                    >
                      ML-2 (Broad)
                    </EuiLink>
                  </li>
                  <li>
                    <EuiLink
                      onClick={() => {
                        window.open(`${defaultURL}/form/ML-3/01-1987`, "_blank");
                      }}
                    >
                      ML-3 (Special)
                    </EuiLink>
                  </li>
                </ul>
              </CoveragePopover>
            }
          >
            <FormSelect
              name={`locations.${tabItem}.perils`}
              label={"Perils"}
              rules={{ required: true }}
              isInvalid={!!errors.locations?.[0]?.perils}
              options={[
                { value: "ML1", text: "ML-1" },
                { value: "ML2", text: "ML-2" },
                { value: "ML3", text: "ML-3" },
                { value: "ML5", text: "ML-5" },
              ]}
              key={tabItem}
            />
          </EuiFormRow>

          <EuiFormRow
            fullWidth
            label="Valuation"
            isInvalid={!!errors.locations?.[0]?.valuation}
            error={errors.locations?.[0]?.valuation?.message}
            labelAppend={
              <CoveragePopover title="Valuation" description="What is valuation?">
                <ul>
                  <li>
                    <strong>Replacement Cost</strong> - Property is replaced with like construction and quality
                  </li>
                  <EuiSpacer />
                  <li>
                    <strong>Actual Cash Value</strong> - Property is valued to include depreciation
                  </li>
                </ul>
              </CoveragePopover>
            }
          >
            <FormSelect
              name={`locations.${tabItem}.valuation`}
              label={"Valuation"}
              isInvalid={!!errors.locations?.[0]?.valuation}
              rules={{ required: true }}
              options={[
                { value: "RC", text: "Replacement Cost" },
                { value: "ACV", text: "Actual Cash Value" },
                { value: "IACV", text: "Index Actual Cash Value" },
              ]}
              key={tabItem}
            />
          </EuiFormRow>

          {valuation === "RC" || valuation === "IACV" ? (
            <>
              <EuiFormRow fullWidth label={<span>Replacement Cost Estimator</span>}>
                <EuiButton onClick={rcButtonClick}>Edit Estimator</EuiButton>
              </EuiFormRow>

              <EuiFormRow
                fullWidth
                isInvalid={!!errors.locations?.[0]?.RCcovA}
                error={errors.locations?.[0]?.RCcovA?.message}
                label={
                  <>
                    <span>Replacement Cost Limit</span>{" "}
                    <EuiTextColor color="subdued" style={{ paddingLeft: "10px" }}>
                      Replacement Cost Coverage A
                    </EuiTextColor>
                  </>
                }
              >
                <Controller
                  name={`locations.${tabItem}.RCcovA` as `${string}`}
                  rules={{ required: true }}
                  key={tabItem}
                  render={({ field }) => {
                    const { onChange, value, ref, ...rest } = field;
                    return (
                      <NumberFormat
                        customInput={EuiFieldText}
                        thousandSeparator={true}
                        prefix={"$"}
                        decimalScale={0}
                        value={value}
                        isInvalid={!!errors.locations?.[0]?.RCcovA}
                        onValueChange={(target) => {
                          onChange(target.value);
                        }}
                        disabled={false}
                        inputRef={ref}
                        {...rest}
                      />
                    );
                  }}
                />
              </EuiFormRow>

              <EuiFormRow
                fullWidth
                label="Replacement Cost Percentage"
                isInvalid={!!errors.locations?.[0]?.RCpercentage}
                error={errors.locations?.[0]?.RCpercentage?.message}
              >
                <FormSelect
                  name={`locations.${tabItem}.RCpercentage`}
                  label={"Replacement Cost Percentage"}
                  disabled={valuation === "IACV"}
                  rules={{ required: true }}
                  isInvalid={!!errors.locations?.[0]?.RCpercentage}
                  options={[
                    { value: 100, text: "100%" },
                    { value: 95, text: "95%" },
                    { value: 92, text: "92%" },
                    { value: 90, text: "90%" },
                    { value: 60, text: "60%" },
                  ]}
                  key={tabItem}
                />
              </EuiFormRow>
            </>
          ) : null}

          <EuiFormRow
            fullWidth
            isInvalid={!!errors.locations?.[0]?.covA}
            error={errors.locations?.[0]?.covA?.message}
            label={
              <>
                <span>Dwelling Limit</span>{" "}
                <EuiTextColor color="subdued" style={{ paddingLeft: "10px" }}>
                  Coverage A
                </EuiTextColor>
              </>
            }
          >
            <Controller
              name={`locations.${tabItem}.covA` as `${string}`}
              rules={{ required: true }}
              key={tabItem}
              render={({ field }) => {
                const { onChange, value, ref, ...rest } = field;
                return (
                  <NumberFormat
                    customInput={EuiFieldText}
                    thousandSeparator={true}
                    prefix={"$"}
                    isInvalid={!!errors.locations?.[0]?.covA}
                    decimalScale={0}
                    value={value}
                    onValueChange={(target) => {
                      onChange(target.value);
                    }}
                    disabled={valuation === "RC" || valuation === "IACV"}
                    inputRef={ref}
                    {...rest}
                    onBlur={() => {
                      onChange(Math.round(value / 1000) * 1000);
                      rest.onBlur();
                    }}
                  />
                );
              }}
            />
          </EuiFormRow>
          <EuiFormRow
            fullWidth
            isInvalid={!!errors.locations?.[0]?.covB}
            error={errors.locations?.[0]?.covB?.message}
            label={
              <>
                <span>Related Structures</span>{" "}
                <EuiTextColor color="subdued" style={{ paddingLeft: "10px" }}>
                  Additional Coverage B
                </EuiTextColor>
              </>
            }
          >
            <IncludedCoverageField
              tabItem={tabItem}
              isInvalid={!!errors.locations?.[0]?.covB}
              name={`locations.${tabItem}.covB` as `${string}`}
              conversion={(coverageA) => {
                return `${Math.round(parseInt(coverageA) * 0.1)}`;
              }}
            />
          </EuiFormRow>
          <EuiFormRow
            fullWidth
            isInvalid={!!errors.locations?.[0]?.covC}
            error={errors.locations?.[0]?.covC?.message}
            label={
              <>
                <span>Contents</span>{" "}
                <EuiTextColor color="subdued" style={{ paddingLeft: "10px" }}>
                  Additional Coverage C
                </EuiTextColor>
              </>
            }
          >
            <IncludedCoverageField
              tabItem={tabItem}
              isInvalid={!!errors.locations?.[0]?.covC}
              name={`locations.${tabItem}.covC` as `${string}`}
              conversion={(coverageA, forms) => {
                if (forms.find((item: any) => item.name === "ML55")) {
                  return `${Math.round(parseInt(coverageA) * 0.7)}`;
                } else {
                  return `${Math.round(parseInt(coverageA) * 0.5)}`;
                }
              }}
            />
          </EuiFormRow>
          <EuiFormRow
            fullWidth
            isInvalid={!!errors.locations?.[0]?.covD}
            error={errors.locations?.[0]?.covD?.message}
            label={
              <>
                <span>Additional Living Expenses</span>{" "}
                <EuiTextColor color="subdued" style={{ paddingLeft: "10px" }}>
                  Additional Coverage D
                </EuiTextColor>
              </>
            }
          >
            <IncludedCoverageField
              tabItem={tabItem}
              isInvalid={!!errors.locations?.[0]?.covD}
              name={`locations.${tabItem}.covD` as `${string}`}
              conversion={(coverageA) => {
                return `${Math.round(parseInt(coverageA) * 0.2)}`;
              }}
            />
          </EuiFormRow>

          <EuiFormRow
            fullWidth
            label="Deductible"
            isInvalid={!!errors.locations?.[0]?.deductible}
            error={errors.locations?.[0]?.deductible?.message}
          >
            <FormSelect
              name={`locations.${tabItem}.deductible`}
              label={"Deductible"}
              isInvalid={!!errors.locations?.[0]?.deductible}
              rules={{ required: true }}
              options={[
                { value: 500, text: "$500" },
                { value: 1000, text: "$1,000" },
                { value: 2500, text: "$2,500" },
                { value: 5000, text: "$5,000" },
                { value: 10000, text: "$10,000" },
              ]}
              key={tabItem}
            />
          </EuiFormRow>
        </EuiDescribedFormGroup>
        <EuiHorizontalRule />
        <EuiDescribedFormGroup
          title={<h3>{"Liability Coverage"}</h3>}
          description={"General information for the property"}
          className="wcicRaterDescribedFormGroup"
        >
          <EuiFormRow
            fullWidth
            label="Liability Form"
            isInvalid={!!errors.locations?.[0]?.liabilityForm}
            error={errors.locations?.[0]?.liabilityForm?.message}
            labelAppend={
              <CoveragePopover title="Liability Form" description="What is liability?">
                <ul>
                  <li>
                    <EuiLink
                      onClick={() => {
                        window.open(`${defaultURL}/form/ML-9/01-1987`, "_blank");
                      }}
                    >
                      ML-9
                    </EuiLink>
                  </li>
                  <EuiSpacer />
                  <li>
                    <EuiLink
                      onClick={() => {
                        window.open(`${defaultURL}/form/ML-10/01-1987`, "_blank");
                      }}
                    >
                      ML-10
                    </EuiLink>
                  </li>
                </ul>
              </CoveragePopover>
            }
          >
            <FormSelect
              name={`locations.${tabItem}.liabilityForm`}
              label={"Liability Form"}
              isInvalid={!!errors.locations?.[0]?.liabilityForm}
              rules={{ required: true }}
              options={[
                { value: "None", text: "None" },
                { value: "ML9", text: "ML-9 Personal Liability" },
                { value: "ML10", text: "ML-10 Farmers Liability" },
              ]}
              key={tabItem}
            />
          </EuiFormRow>
          {liabilityForm === "ML9" || liabilityForm === "ML10" ? (
            <>
              <EuiFormRow
                fullWidth
                label="Liability"
                isInvalid={!!errors.locations?.[0]?.liability}
                error={errors.locations?.[0]?.liability?.message}
              >
                <FormSelect
                  name={`locations.${tabItem}.liability`}
                  label={"Liability"}
                  isInvalid={!!errors.locations?.[0]?.liability}
                  rules={{ required: true }}
                  options={[
                    { value: 100000, text: "$100,000" },
                    { value: 300000, text: "$300,000" },
                    { value: 500000, text: "$500,000" },
                    { value: 1000000, text: "$1,000,000" },
                  ]}
                  key={tabItem}
                />
              </EuiFormRow>
              <EuiFormRow
                fullWidth
                label="Medical Payments"
                isInvalid={!!errors.locations?.[0]?.medpay}
                error={errors.locations?.[0]?.medpay?.message}
              >
                <NumberFormat
                  customInput={EuiFieldText}
                  thousandSeparator={true}
                  prefix={"$"}
                  decimalScale={0}
                  // value={5000} // TODO
                  value={getValues(`locations.0.medpay`)}
                  // isInvalid={!!get(errors, `medpay`)}
                  disabled={true}
                />
              </EuiFormRow>
            </>
          ) : null}
        </EuiDescribedFormGroup>
      </EuiPanel>
      <EuiSpacer />
      <EuiButton
        onClick={() => {
          setIsFlyoutVisible(true);
        }}
      >
        Add Additional Coverages
      </EuiButton>

      <EuiSpacer />
      <FormPanel
        forms={forms}
        removeForm={removeForm}
        policyForms={policyForms}
        removePolicyForm={removePolicyForm}
        formsPrefix={`locations.${tabItem}.forms`}
        formsData={formsData}
      />
      {flyout}
    </>
  );
};

export default LocationContent;

const FormPanel = ({
  forms,
  policyForms,
  removePolicyForm,
  formsPrefix,
  formsData,
  removeForm,
}: {
  forms: UseFieldArrayReturn<LPQuoteInput, "locations.0.forms", "id">["fields"];
  policyForms: UseFieldArrayReturn<LPQuoteInput, "forms", "id">["fields"];
  removePolicyForm: UseFieldArrayReturn<LPQuoteInput, "forms", "id">["remove"];
  formsPrefix: string;
  removeForm: UseFieldArrayReturn<LPQuoteInput, "locations.0.forms", "id">["remove"];
  formsData: Record<string, FormDataType>;
}) => {
  return (
    <EuiPanel hasBorder>
      <EuiTitle>
        <h2>Additional Coverages</h2>
      </EuiTitle>
      <EuiSpacer size="m" />
      <EuiFlexGroup gutterSize="l" direction="column">
        {forms.length === 0 && policyForms.length === 0 ? (
          <EuiFlexItem>
            <EuiPanel paddingSize="l" color="subdued">
              <EuiFlexGroup justifyContent="spaceAround">
                <EuiFlexItem style={{ textAlign: "center" }}>
                  <EuiSpacer />
                  <EuiTitle>
                    <h1>
                      <EuiTextColor color="subdued">No Additional Coverages Added</EuiTextColor>
                    </h1>
                  </EuiTitle>
                  <EuiSpacer />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiPanel>
          </EuiFlexItem>
        ) : null}
        {forms.length > 0
          ? forms.map((form, index) => {
              return (
                <RaterForm
                  key={form.id}
                  index={index}
                  formsPrefix={formsPrefix}
                  removeForm={removeForm}
                  formData={formsData[form.name]}
                  formName={form.name}
                  formID={form.formID}
                />
              );
            })
          : null}
        {policyForms.length > 0
          ? policyForms.map((form, index) => {
              return (
                <RaterForm
                  key={form.id}
                  index={index}
                  formsPrefix={`forms`}
                  removeForm={removePolicyForm}
                  formData={formsData[form.name]}
                  formName={form.name}
                  formID={form.formID}
                />
              );
            })
          : null}
      </EuiFlexGroup>
    </EuiPanel>
  );
};
