import { NumberSchema, StringSchema, number, object, string } from "yup";

import { FormDataType } from "../../components/FormDataTypes";

export const formsData: Record<string, FormDataType> = {
  ML216: {
    description: "Provides a credit for the installation of a fire alarm, burglar alarm or automatic sprinkler system",
    formTitle: "Protective Devices",
    displayFormName: "ML-216",
    policyForm: false,
    recommended: true,
    nameEdition: { name: "ML-216", edition: "01-1987" },
    yupShape: object().shape({
      protection: string().oneOf(["smokeDetector", "fireAlarm", "centralStation", "sprinklers"]).required(),
    }),
    inputs: [
      {
        name: "protection",
        type: "select",
        label: "Protection Device",
        options: [
          {
            value: "smokeDetector",
            text: "Fire Alarm and/or Smoke Detectors",
          },
          {
            value: "fireAlarm",
            text: "Fire/Police Alarm or Video Monitoring",
          },
          {
            value: "centralStation",
            text: "Central station burglary and/or fire alarm systems",
          },
          {
            value: "sprinklers",
            text: "Sprinkler Systems",
          },
        ],
      },
    ],
    defaultInputs: {
      protection: "smokeDetector",
    },
  },
  ML55: {
    description: "Offers replacement cost coverage for personal property insured under Coverage C",
    formTitle: "Replacement Cost Contents",
    displayFormName: "ML-55",
    inputs: [],
    recommended: true,
    nameEdition: { name: "ML-55", edition: "01-1987" },
  },
  ML17: {
    description:
      "Covers direct physical loss or damage caused by theft or attempted theft of building materials, supplies, and fixtures on or next to the insured premises where a covered structure is under construction.",
    formTitle: "Theft of Building Materials",
    displayFormName: "ML-17",
    recommended: false,
    nameEdition: { name: "ML-17", edition: "06-1999" },
    yupShape: object().shape({
      amount: number()
        .positive()
        .min(100)
        .required()
        .label("Coverage amount")
        .typeError((field) => `${field.label} must be a number`),
    }),
    inputs: [{ name: "amount", type: "money", label: "Amount" }],
    defaultInputs: {
      amount: 1000,
    },
  },
  ML23: {
    description:
      "Amends the definition of insured to include a person who lives with the insured and is not an insured, guest, residence employee, tenant, roomer or boarder. This person is covered for personal property and personal liability.",
    formTitle: "Additional Household Members Coverage",
    displayFormName: "ML-23",
    nameEdition: { name: "ML-23", edition: "09-2007" },
    yupShape: object().shape({
      additionalMembers: number()
        .positive()
        .required()
        .typeError((field) => `${field.label} must be a number`)
        .label("Additional members"),
    }),
    inputs: [{ name: "additionalMembers", type: "number", label: "Additional Members", decimalScale: 0 }],
    defaultInputs: {
      additionalMembers: 1,
    },
  },
  ML29: {
    description:
      "Amends the definition of insured to include a person who does not live with the insured but who is related to the insured by blood, marriage, or adoption, and who regularly resides in an assisted living care facility. This form provides limited personal property and limited personal liability.",
    formTitle: "Additional Living Care Facility Resident Coverage",
    displayFormName: "ML-29",
    nameEdition: { name: "ML-29", edition: "09-2007" },
    yupShape: object().shape({
      additionalMembers: number()
        .positive()
        .required()
        .typeError((field) => `${field.label} must be a number`)
        .label("Additional members"),
    }),
    inputs: [{ name: "additionalMembers", type: "number", label: "Additional Members", decimalScale: 0 }],
    defaultInputs: {
      additionalMembers: 1,
    },
  },
  ML31: {
    description: "Coverage may be provided for unit owners additions and alterations",
    formTitle: "Unit Owners Additions and Alterations",
    displayFormName: "ML-31",
    nameEdition: { name: "ML-31", edition: "06-1999" },
    yupShape: object().shape({
      amount: number()
        .positive()
        .required()
        .typeError((field) => `${field.label} must be a number`)
        .label("Coverage amount"),
    }),
    inputs: [{ name: "amount", type: "money", label: "Amount" }],
    defaultInputs: {
      amount: 1000, // TODO What should the default amount be?
    },
  },
  ML32: {
    description:
      "Coverage may be provided against covered causes of loss, except as excluded or limited, at the premiums shown in the Premium Section of the manual.",
    formTitle: "Condominium Unit-Owner Additions Special Coverage",
    displayFormName: "ML-32",
    nameEdition: { name: "ML-32", edition: "06-1999" },
    yupShape: object().shape({
      limitSelection: string().oneOf(["R", "H"]).required().label("Limit"),
      amount: number()
        .positive()
        .typeError((field) => `${field.label} must be a number`)
        .required()
        .label("Coverage amount"),
    }),
    inputs: [
      {
        name: "limitSelection",
        type: "select",
        label: "Limit",
        options: [
          {
            value: "R",
            text: "Regular Limit",
          },
          {
            value: "H",
            text: "Higher Limit",
          },
        ],
      },
      { name: "amount", type: "money", label: "Amount" },
    ],
    defaultInputs: {
      limitSelection: "R",
      amount: 1000,
    },
  },
  ML33: {
    description:
      "For an additional premium, we provide coverage if the condominium unit covered under this policy is rented to others",
    formTitle: "Unit Owners Rental to Others",
    displayFormName: "ML-33",
    nameEdition: { name: "ML-33", edition: "01-1987" },
    yupShape: object().shape({
      amount: number()
        .positive()
        .required()
        .typeError((field) => `${field.label} must be a number`)
        .label("Coverage amount"),
    }),
    inputs: [{ name: "amount", type: "money", label: "Amount" }],
    defaultInputs: {
      amount: 100,
    },
  },
  ML34: {
    description:
      "The policy may be extended to cover private structures owned solely by the insured and located on the described premises",
    formTitle: "Related Private Structures and Condo Fixtures",
    displayFormName: "ML-34",
    nameEdition: { name: "ML-34", edition: "06-1999" },
    yupShape: object().shape({
      miscRealProperty: number()
        .positive()
        .required()
        .typeError((field) => `${field.label} must be a number`)
        .label("Miscellaneous real property"),
      ownersPrivateStructures: number()
        .positive()
        .required()
        .typeError((field) => `${field.label} must be a number`)
        .label("Owners private structures"),
    }),
    inputs: [
      { name: "miscRealProperty", type: "money", label: "Misc Real Property Amount" },
      { name: "ownersPrivateStructures", type: "money", label: "Owners Private Structures Amount" },
    ],
    defaultInputs: {
      miscRealProperty: 1000,
      ownersPrivateStructures: 1000,
    },
  },
  ML35: {
    description:
      "The policy may be extended to cover loss assessments for which the insured may be liable to the association of condominium unit owners",
    formTitle: "Loss Assessment Coverage",
    displayFormName: "ML-35",
    nameEdition: { name: "ML-35", edition: "09-2008" },
    yupShape: object().shape({
      amount: number()
        .positive()
        .required()
        .typeError((field) => `${field.label} must be a number`)
        .label("Coverage amount"),
    }),
    inputs: [{ name: "amount", type: "money", label: "Amount" }],
    defaultInputs: {
      amount: 1000,
    },
  },
  ML37: {
    description:
      "Coverage may be provided for breakage of window and door plates that are part of the unit owners' portion of the building",
    formTitle: "Glass Breakage",
    displayFormName: "ML-37",
    nameEdition: { name: "ML-37", edition: "06-1999" },
    inputs: [],
  },
  ML42: {
    description:
      "Coverage may be provided for the liability of an insured arising from an office, professional, private school, or studio occupancy on the insured premises",
    formTitle: "Office Professional, Private School, or Studio (On Premise)",
    displayFormName: "ML-42",
    nameEdition: { name: "ML-42", edition: "01-1987" },
    yupShape: object().shape({
      instruction: string()
        .oneOf(["N", "Y"], (field) => `${field.label} must be Yes or No`)
        .required()
        .label("Instuction"),
    }),
    inputs: [
      {
        name: "instruction",
        type: "select",
        label: "Instruction On Premises",
        options: [
          {
            value: "Y",
            text: "Yes",
          },
          {
            value: "N",
            text: "No",
          },
        ],
      },
    ],
    defaultInputs: {
      instruction: "Y",
    },
  },
  ML43: {
    description:
      "Coverage may be provided for the liability of an insured arising from an office, professional, private school, or studio occupancy conducted on the described premises",
    formTitle: "Office Professional, Private School, or Studio (Off Premise)",
    displayFormName: "ML-43",
    nameEdition: { name: "ML-43", edition: "01-1987" },
    inputs: [],
  },
  // ML46: {
  //   description: "",
  //   formTitle: "Personal Injury",
  //   displayFormName: "ML-46",
  //   nameEdition: { name: "ML-46", edition: "01-1987" },
  //   inputs: [],
  // },
  ML46A: {
    description:
      "Removes personal injury coverage contained in the policy and replaces it with the coverage listed on the policy form. ",
    formTitle: "Personal Injury",
    displayFormName: "ML-46A",
    nameEdition: { name: "ML-46A", edition: "07-2014" },
    inputs: [],
  },
  ML50: {
    description: "Extends coverage to loss assessments charged by the homeowners association",
    formTitle: "Homeowners Association Loss Assessment",
    displayFormName: "ML-50",
    nameEdition: { name: "ML-50", edition: "06-1999" },
    yupShape: object().shape({
      amount: number()
        .positive()
        .required()
        .typeError((field) => `${field.label} must be a number`)
        .label("Coverage amount"),
    }),
    inputs: [{ name: "amount", type: "money", label: "Amount" }],
    defaultInputs: {
      amount: 1000,
    },
  },
  ML51: {
    description: "Coverage in excess of the amount provided for building additions and alterations",
    formTitle: "Building Additions and Alterations",
    displayFormName: "ML-51",
    nameEdition: { name: "ML-51", edition: "01-1987" },
    yupShape: object().shape({
      amount: number()
        .positive()
        .required()
        .typeError((field) => `${field.label} must be a number`)
        .label("Coverage amount"),
    }),
    inputs: [{ name: "amount", type: "money", label: "Amount" }],
    defaultInputs: {
      amount: 10000,
    },
  },
  ML52A: {
    description: "Excludes coverage for bodily injury or property damage in relation to a trampoline",
    formTitle: "Trampoline Exclusion",
    displayFormName: "ML-52A",
    nameEdition: { name: "ML-52A", edition: "12-1998" },
    inputs: [],
    liabilityForm: true,
  },
  ML54: {
    description:
      "Earthquake coverage may be written at the premiums shown in the Premium Section of the manual. Please include increased Coverage B or C when calculating premium.",
    formTitle: "Earthquake",
    displayFormName: "ML-54",
    nameEdition: { name: "ML-54", edition: "01-1987" },
    inputs: [],
  },
  ML57: {
    description:
      "Coverage for credit card, forgery, and counterfeit money may be increased at the premiums shown in the premium section of the manual.",
    formTitle: "Credit Card, Forgery and Counterfeit Money",
    displayFormName: "ML-57",
    nameEdition: { name: "ML-57", edition: "01-1987" },
    yupShape: object().shape({
      amount: string()
        .oneOf(["2500", "5000", "7500", "10000"])
        .required()
        .typeError((field) => `${field.label} must be a number`)
        .label("Coverage amount"),
    }),
    inputs: [
      {
        name: "amount",
        type: "select",
        label: "Instruction On Premises",
        options: [
          {
            value: "2500",
            text: "$2,500",
          },
          {
            value: "5000",
            text: "$5,000",
          },
          {
            value: "7500",
            text: "$7,500",
          },
          {
            value: "10000",
            text: "$10,000",
          },
        ],
      },
    ],
    defaultInputs: {
      amount: 2500,
    },
  },
  ML58: {
    description:
      "Coverage for theft while the premise is rented to others may be added at the additional premium shown in the premium section of the manual",
    formTitle: "Residential Rental Theft",
    displayFormName: "ML-58",
    nameEdition: { name: "ML-58", edition: "01-1987" },
    inputs: [],
  },
  ML65H: {
    description: "Provides higher sublimits for certain property",
    formTitle: "Higher Limits on Certain Property",
    displayFormName: "ML-65H",
    nameEdition: { name: "ML-65H", edition: "01-1987" },
    yupShape: object().shape({
      option: string()
        .oneOf(
          ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14"],
          (field) => `${field.label} should be selected from the options`
        )
        .required()
        .label("Property"),
      amount: number()
        .positive()
        .required()
        .typeError((field) => `${field.label} must be a number`)
        .label("Coverage amount"),
    }),
    inputs: [
      {
        name: "option",
        type: "select",
        label: "Instruction On Premises",
        options: [
          {
            value: "1",
            text: "Business property",
          },
          {
            value: "2",
            text: "Business property away from premises",
          },
          {
            value: "3",
            text: "Dismounted camper bodies",
          },
          {
            value: "4",
            text: "Domestic appliances on the insured premises",
          },
          {
            value: "5",
            text: "Electronic apparatus and its accessories",
          },
          {
            value: "6",
            text: "Grave markers",
          },
          {
            value: "7",
            text: "Guns",
          },
          {
            value: "8",
            text: "Money",
          },
          {
            value: "9",
            text: "Motorized vehicles",
          },
          {
            value: "10",
            text: "Securities",
          },
          {
            value: "11",
            text: "Silverware, goldware & pewterware",
          },
          {
            value: "12",
            text: "Trailers not used with watercraft",
          },
          {
            value: "13",
            text: "Unscheduled jewlery, etc.",
          },
          {
            value: "14",
            text: "Watercraft",
          },
        ],
      },
      { name: "amount", type: "money", label: "Amount" },
    ],
    defaultInputs: {
      option: "1",
      amount: 1000,
    },
  },
  ML66: {
    description:
      "The limit of liability under Coverage C - Personal Property which applies to personal property away from the insured premises is increased at the requested amount.",
    formTitle: "Increased Coverage C Away from Premises",
    displayFormName: "ML-66",
    nameEdition: { name: "ML-66", edition: "01-1987" },
    yupShape: object().shape({
      amount: number()
        .positive()
        .required()
        .typeError((field) => `${field.label} must be a number`)
        .label("Coverage amount"),
    }),
    inputs: [{ name: "amount", type: "money", label: "Amount" }],
    defaultInputs: {
      amount: 1000,
    },
  },
  ML69: {
    description: "Provides increased amounts of insurance on Physicians, Surgeons, Dentists and Veterinarians property",
    formTitle: "Physicians, Surgeons, Dentists and Veterinarians",
    displayFormName: "ML-69",
    nameEdition: { name: "ML-69", edition: "01-1987" },
    yupShape: object().shape({
      amount: number()
        .positive()
        .required()
        .typeError((field) => `${field.label} must be a number`)
        .label("Coverage amount"),
    }),
    inputs: [{ name: "amount", type: "money", label: "Amount" }],
    defaultInputs: {
      amount: 1000,
    },
  },
  ML70: {
    description: "Extends liability to additional premises - rented to others",
    formTitle: "Additional Residential Premises-Rented to Others",
    displayFormName: "ML-70",
    nameEdition: { name: "ML-70", edition: "01-1987" },
    defaultInputs: {
      options: "1",
    },
    inputs: [
      {
        name: "options",
        type: "select",
        label: "Number of Families",
        options: [
          {
            value: "1",
            text: "1",
          },
          {
            value: "2",
            text: "2",
          },
        ],
      },
    ],
  },
  ML71: {
    description:
      "Coverage L - Personal Liability and, if included, Coverage M - Medical Payments to Others apply to the business activities of the insured as described in this endorsement or in the Declarations",
    formTitle: "Business Pursuits",
    displayFormName: "ML-71",
    nameEdition: { name: "ML-71", edition: "01-1987" },
    defaultInputs: {
      options: "1",
    },
    yupShape: object().shape({
      options: string().oneOf(["1", "2", "3", "4", "5"]).required().label("Business pursuits"),
    }),
    inputs: [
      {
        name: "options",
        type: "select",
        label: "Business Pursuits",
        options: [
          {
            value: "1",
            text: "Clerical Office Employee",
          },
          {
            value: "2",
            text: "Salesperson, Collector or Manager (1)",
          },
          {
            value: "3",
            text: "Salesperson, Collector or Manager (2)",
          },
          {
            value: "4",
            text: "Teacher (1)",
          },
          {
            value: "5",
            text: "Teacher (2)",
          },
        ],
      },
    ],
  },
  ML72A: {
    addable: (rateOptions, _location, selectedForms) => {
      const exclusiveForms = ["WCIC1100"];
      if (selectedForms && selectedForms.length > 0) {
        if (selectedForms.some((form) => exclusiveForms.includes(form))) {
          return false;
        }
      }
      const hasExclusivePolicyForms = rateOptions.forms.some((form) => {
        return exclusiveForms!.includes(form.name);
      });
      const hasExclusiveLocForms = rateOptions.locations[0].forms.some((form) => {
        return exclusiveForms!.includes(form.name);
      });
      if (hasExclusiveLocForms || hasExclusivePolicyForms) {
        return false;
      }
      return true;
    },
    description: "Adds sublimit for added water damages coverage",
    formTitle: "Added Water Damages Coverage",
    displayFormName: "ML-72A",
    nameEdition: { name: "ML-72A", edition: "05-2013" },
    yupShape: object().shape({
      amount: string()
        .oneOf(["2500", "5000"])
        .required()
        .typeError((field) => `${field.label} must be a number`)
        .label("Coverage amount"),
    }),
    inputs: [
      {
        name: "amount",
        type: "select",
        label: "Business Type",
        options: [
          {
            value: "2500",
            text: "$2,500",
          },
          {
            value: "5000",
            text: "$5,000",
          },
        ],
      },
    ],
    defaultInputs: {
      amount: "2500",
    },
  },
  ML75: {
    multiAdd: true,
    description: "Coverage may be provided for watercraft not otherwise covered by the policy",
    formTitle: "Outboard Motors and Watercraft",
    displayFormName: "ML-75",
    nameEdition: { name: "ML-75", edition: "01-1987" },
    defaultInputs: {
      boatType: "1",
      boatSpeed: "1",
      boatLength: "1",
    },
    yupShape: object().shape({
      boatType: number().oneOf([1, 2]).required(),
      boatSpeed: number().when("boatType", (boatType: number, schema: NumberSchema) => {
        if (boatType === 2) {
          return schema.oneOf([1, 2, 3, 4]).required();
        }
        return schema;
      }),
      // @ts-ignore
      boatLength: number().when(
        ["boatType", "boatSpeed"],
        // @ts-ignore
        (boatType: number, boatSpeed: number, schema: NumberSchema) => {
          if (boatType === 2) {
            if (boatSpeed === 1 || boatSpeed === 2) {
              return schema.oneOf([1, 2, 3]).required().label("");
            }
            if (boatSpeed === 3) {
              return schema.oneOf([1, 2]).required().label("");
            }
            if (boatSpeed === 4) {
              return schema.oneOf([2]).required().label("");
            }
            return schema.optional();
          }
          return schema.optional();
        }
      ),
    }),
    inputs: [
      {
        name: "boatType",
        type: "select",
        label: "Boat Type",
        options: [
          {
            value: "1",
            text: "Outboard >50hp",
          },
          {
            value: "2",
            text: "Inboard or Inboard-Outboard Motorboats and Sailboats",
          },
        ],
      },
      {
        name: "boatSpeed",
        type: "select",
        label: "Boat Speed",
        requires: { option: "boatType", value: "2" },
        options: [
          {
            value: "1",
            text: "Under 16 MPH",
          },
          {
            value: "2",
            text: "16-30 MPH",
          },
          {
            value: "3",
            text: "Over 30 MPH",
          },
          {
            value: "4",
            text: "Sailboat - No Aux Power",
          },
        ],
      },
      {
        name: "boatLength",
        type: "select",
        label: "Boat Length",
        requires: [
          { option: "boatType", value: "2" },
          [
            { option: "boatSpeed", value: "1" },
            { option: "boatSpeed", value: "2" },
          ],
        ],
        options: [
          {
            value: "1",
            text: "Less than 26 feet",
          },
          {
            value: "2",
            text: "26 to 40 feet",
          },
          {
            value: "3",
            text: "More than 40 feet",
          },
        ],
      },
      {
        name: "boatLength",
        type: "select",
        label: "Boat Length",
        requires: [
          { option: "boatType", value: "2" },
          { option: "boatSpeed", value: "3" },
        ],
        options: [
          {
            value: "1",
            text: "Less than 26 feet",
          },
          {
            value: "2",
            text: "26 to 40 feet",
          },
        ],
      },
      {
        name: "boatLength",
        type: "select",
        label: "Boat Length",
        requires: [
          { option: "boatType", value: "2" },
          { option: "boatSpeed", value: "4" },
        ],
        options: [
          {
            value: "2",
            text: "26 to 40 feet",
          },
        ],
      },
    ],
  },
  ML77: {
    description:
      "Provides a capped annual amount of insurance to cover the cost of cleanup of on premises pollution events",
    formTitle: "Pollution Extension",
    displayFormName: "ML-77",
    nameEdition: { name: "ML-77", edition: "10-2002" },
    inputs: [],
  },
  // TODO
  ML79: {
    description:
      "Provides additional coverage limits when a catastrophic event has occurred and one or more counties in this state have been designated a disaster area by the appropriate state or federal sources.",
    formTitle: "Storm Extender Endorsement",
    displayFormName: "ML-79",
    nameEdition: { name: "ML-79", edition: "02-1999" },
    inputs: [],
  },
  ML82: {
    description:
      "The policy may be extended to provide coverage for bodily injury and property damage resulting from the ownership, maintenance, use, loading, or unloading of golf carts",
    formTitle: "Golf Cart Liability Extension",
    displayFormName: "ML-82",
    nameEdition: { name: "ML-82", edition: "06-1999" },
    liabilityForm: true,
    inputs: [
      {
        name: "amount",
        type: "select",
        label: "Number of carts",
        options: [
          {
            value: "1",
            text: "1",
          },
          {
            value: "2",
            text: "2",
          },
          {
            value: "3",
            text: "3",
          },
          {
            value: "4",
            text: "4",
          },
        ],
      },
    ],
    yupShape: object().shape({
      amount: string().oneOf(["1", "2", "3", "4"]).required().label("Number of carts"),
    }),
    defaultInputs: {
      amount: "1",
    },
  },
  ML86: {
    description:
      "Limits liability coverage and personal property coverage to on premises only. The form is used for secondary/seasonal policies.",
    formTitle: "Secondary/Seasonal Homeowners Coverage Limitation",
    displayFormName: "ML-86",
    nameEdition: { name: "ML-86", edition: "07-2010" },
    inputs: [],
  },
  ML124: {
    description:
      "When a building or structure covered by the policy sustains a covered loss to a roof surface caused by windstorm or hail, the claim will be adjusted on the basis of the actual cash value of the loss",
    formTitle: "Roof Surface ACV Loss Settlement (Windstorm or Hail)",
    displayFormName: "ML-124",
    nameEdition: { name: "ML-124", edition: "08-2013" },
    inputs: [],
  },
  ML147: {
    addable: (rateOptions, location, selectedForms) => {
      const exclusiveForms = ["ML148", "WCIC150A"];
      if (selectedForms && selectedForms.length > 0) {
        if (selectedForms.some((form) => exclusiveForms.includes(form))) {
          return false;
        }
      }
      const hasExclusivePolicyForms = rateOptions.forms.some((form) => {
        return exclusiveForms!.includes(form.name);
      });
      const hasExclusiveLocForms = rateOptions.locations[location].forms.some((form) => {
        return exclusiveForms!.includes(form.name);
      });
      if (hasExclusiveLocForms || hasExclusivePolicyForms) {
        return false;
      }
      return true;
    },
    description:
      "Increases the sublimit for Coverage C - Personal Property and adds $500 in refrigerated food products",
    formTitle: "Homeowners Increased Coverage",
    displayFormName: "ML-147",
    nameEdition: { name: "ML-147", edition: "01-1987" },
    inputs: [],
    // exclusiveForms: ["ML148", "WCIC150A"],
  },
  ML148: {
    addable: (rateOptions, _location, selectedForms) => {
      const exclusiveForms = ["WCIC150A", "ML147"];
      if (selectedForms && selectedForms.length > 0) {
        if (selectedForms.some((form) => exclusiveForms.includes(form))) {
          return false;
        }
      }
      const hasExclusivePolicyForms = rateOptions.forms.some((form) => {
        return exclusiveForms!.includes(form.name);
      });
      const hasExclusiveLocForms = rateOptions.locations[0].forms.some((form) => {
        return exclusiveForms!.includes(form.name);
      });
      if (hasExclusiveLocForms || hasExclusivePolicyForms) {
        return false;
      }
      return true;
    },
    description:
      "Increases the sublimit for Coverage C - Personal Property, adds $500 in refrigerated food products, and increases the Coverage L - Personal Liability limit",
    formTitle: "Homeowners Extra Coverage",
    displayFormName: "ML-148",
    nameEdition: { name: "ML-148", edition: "01-1987" },
    inputs: [],
    // exclusiveForms: ["WCIC150A", "ML147"],
  },
  WCIC150A: {
    addable: (rateOptions, _location, selectedForms) => {
      if (parseInt(rateOptions.locations[0].yearBuilt) <= 1980) {
        return false;
      }
      const exclusiveForms = ["ML148", "ML147"];
      if (selectedForms && selectedForms.length > 0) {
        if (selectedForms.some((form) => exclusiveForms.includes(form))) {
          return false;
        }
      }
      const hasExclusivePolicyForms = rateOptions.forms.some((form) => {
        return exclusiveForms!.includes(form.name);
      });
      const hasExclusiveLocForms = rateOptions.locations[0].forms.some((form) => {
        return exclusiveForms!.includes(form.name);
      });
      if (hasExclusiveLocForms || hasExclusivePolicyForms) {
        return false;
      }
      return true;
    },
    description:
      "Increases the sublimit for Coverage C - Personal Property, adds $500 in refrigerated food products, increases the Coverage L limit, and adds $5,000 in added water damages coverage. The home must be constructed after 1980 to qualify. ",
    formTitle: "Homeowners Plus Coverage",
    displayFormName: "WCIC-150A",
    nameEdition: { name: "WCIC-150A", edition: "07-2013" },
    inputs: [],
    // exclusiveForms: ["ML148", "ML147"],
  },
  ML167: {
    description:
      "Residences equipped with dead bolt locks as defined by the policy form shall be entitled to a rate credit",
    formTitle: "Dead Bolt Lock",
    displayFormName: "ML-167",
    nameEdition: { name: "ML-167", edition: "02-1999" },
    inputs: [],
  },
  ML243: {
    description:
      "Amounts of insurance may be automatically increased for Coverages A, B, C, and D on a quarterly basis at an additional surcharge. This form is not available for policies with an ML-4 perils form or policies written at ACV valuation.",
    formTitle: "Inflation Guard",
    displayFormName: "ML-243",
    nameEdition: { name: "ML-243", edition: "01-1987" },
    yupShape: object().shape({
      amount: string().oneOf(["1", "2", "3"]).required(),
    }),
    inputs: [
      {
        name: "amount",
        type: "select",
        label: "Amount",
        options: [
          {
            value: "1",
            text: "1%",
          },
          {
            value: "2",
            text: "2%",
          },
          {
            value: "3",
            text: "3%",
          },
        ],
      },
    ],
    defaultInputs: {
      amount: "1",
    },
  },
  ML305A: {
    description: "Added perils for refrigerated food products. Coverage is available in $500 increments.",
    formTitle: "Refrigerated Food Products",
    displayFormName: "ML-305A",
    nameEdition: { name: "ML-305A", edition: "01-1987" },
    yupShape: object().shape({
      amount: number()
        .positive()
        .required()
        .typeError((field) => `${field.label} must be a number`)
        .label("Coverage amount"),
    }),
    inputs: [{ name: "amount", type: "money", label: "Amount" }],
    defaultInputs: {
      amount: 1000,
    },
  },
  ML326: {
    description:
      "Provides incidental business pursuits coverage. Please refer to the rate manual for eligible classes.",
    formTitle: "Incidental Business Exposure (Including Products Liablity)",
    displayFormName: "ML-326",
    nameEdition: { name: "ML-326", edition: "01-1987" },
    yupShape: object().shape({
      businesses: string().oneOf(["1", "2", "3", "4", "5", "6", "7", "8"]).required().label("Business"),
      rooms: string().when("businesses", (businesses: string, schema: StringSchema) => {
        if (businesses === "3") {
          return schema.oneOf(["1", "2", "3", "4"]).required();
        }
        return schema;
      }),
    }),
    inputs: [
      {
        name: "businesses",
        type: "select",
        label: "Business Type",
        options: [
          {
            value: "1",
            text: "Home Day Care (Flat Rate)",
          },
          {
            value: "2",
            text: "Retail Stores Including Products",
          },
          {
            value: "3",
            text: "Bed and Breakfast (Per Rental Room)",
          },
          {
            value: "4",
            text: "Barber Shop",
          },
          {
            value: "5",
            text: "Beauty Shop",
          },
          {
            value: "6",
            text: "Roadside Stand (Including Products)",
          },
          {
            value: "7",
            text: "Pick Your Own Fruit and Vegetables - No Ladders",
          },
          {
            value: "8",
            text: "Woodworking & Crafts Including Products",
          },
        ],
      },
      {
        name: "rooms",
        type: "select",
        label: "Number of Bedrooms",
        requires: { option: "businesses", value: "3" },
        options: [
          {
            value: "1",
            text: "1",
          },
          {
            value: "2",
            text: "2",
          },
          {
            value: "3",
            text: "3",
          },
          {
            value: "4",
            text: "4",
          },
        ],
      },
    ],
    defaultInputs: {
      businesses: "1",
      rooms: "1",
    },
  },
  ML327: {
    description:
      "?   Provides incidental business pursuits coverage. Please refer to the rate manual for eligible classes.", // TODO Same as 326?
    formTitle: "Incidental Business Exposure (Excluding Products Liablity)",
    displayFormName: "ML-327",
    nameEdition: { name: "ML-327", edition: "01-1987" },
    yupShape: object().shape({
      businesses: string().oneOf(["1", "2"]).required().label("Business"),
    }),
    inputs: [
      {
        name: "businesses",
        type: "select",
        label: "Business Type",
        options: [
          {
            value: "1",
            text: "Retail Stores Excluding Products",
          },
          {
            value: "2",
            text: "Woodworking & Crafts Excluding Products",
          },
        ],
      },
    ],
    defaultInputs: {
      businesses: "1",
    },
  },
  ML342: {
    description:
      "Provides coverage for direct physical loss to a covered underground utility line cause by an occurrence at the insured premises",
    formTitle: "Underground Utility Line Endorsement",
    displayFormName: "ML-342",
    nameEdition: { name: "ML-342", edition: "07-2014" },
    inputs: [],
  },
  ML346BG: {
    description: "Provides coverage for direct physical loss to covered property covered by equipment breakdown",
    formTitle: "Equipment Breakdown Enhancement",
    displayFormName: "ML-346BG",
    nameEdition: { name: "ML-346BG", edition: "09-2013" },
    inputs: [],
  },
  ML363: {
    description:
      "Coverage is extended to include Personal Liability and Medical Payments to Others resulting from the rendering or failure to render professional services relating to barbershop and beauty parlor operations conducted at the insured premises",
    formTitle: "Limited Professional Liability",
    displayFormName: "ML-363",
    nameEdition: { name: "ML-363", edition: "01-1987" }, // TODO Not correct, file not in backend
    yupShape: object().shape({
      option: string().oneOf(["Beauty", "Barber"]).required().label("Business"),
    }),
    inputs: [
      {
        name: "option",
        type: "select",
        label: "Business Type",
        options: [
          {
            value: "Beauty",
            text: "Beauty Parlors",
          },
          {
            value: "Barber",
            text: "Barber Shops",
          },
        ],
      },
    ],
    defaultInputs: {
      option: "Beauty",
    },
  },

  // TODO why does this say "by Insured"?
  ML379: {
    description: "Used to extend liability to additional premises",
    formTitle: "Additional Residential Premises Occupied by Insured",
    displayFormName: "ML-379",
    nameEdition: { name: "ML-379", edition: "01-1987" },
    inputs: [],
  },
  WCIC1100: {
    addable: (rateOptions, _location, selectedForms) => {
      const exclusiveForms = ["ML72A"];
      if (selectedForms && selectedForms.length > 0) {
        if (selectedForms.some((form) => exclusiveForms.includes(form))) {
          return false;
        }
      }
      const hasExclusivePolicyForms = rateOptions.forms.some((form) => {
        return exclusiveForms!.includes(form.name);
      });
      const hasExclusiveLocForms = rateOptions.locations[0].forms.some((form) => {
        return exclusiveForms!.includes(form.name);
      });
      if (hasExclusiveLocForms || hasExclusivePolicyForms) {
        return false;
      }
      return true;
    },
    description:
      "Coverage is designed for low- to moderate-risk flood zones. \nAdds sublimit for added water damages coverage. The form is added in conjunction with Inland Flood Coverage (WCIC-1100)",
    formTitle: "Inland Flood & Added Water Damages Coverage",
    displayFormName: "WCIC-1100 & WCIC-1300",
    nameEdition: { name: "WCIC-1100", edition: "04-2016" }, // TODO Need to merge forms into 1 pdf, showing 1100 currently
    yupShape: object().shape({
      limit: string()
        .oneOf(["5000", "10000", "15000", "20000", "25000", "30000", "40000", "50000"])
        .required()
        .label("Limit"),
    }),
    inputs: [
      {
        name: "limit",
        type: "select",
        label: "Limit",
        options: [
          {
            value: "5000",
            text: "$5,000",
          },
          {
            value: "10000",
            text: "$10,000",
          },
          {
            value: "15000",
            text: "$15,000",
          },
          {
            value: "20000",
            text: "$20,000",
          },
          {
            value: "25000",
            text: "$25,000",
          },
          {
            value: "30000",
            text: "$30,000",
          },
          {
            value: "40000",
            text: "$40,000",
          },
          {
            value: "50000",
            text: "$50,000",
          },
        ],
      },
    ],
    defaultInputs: {
      limit: "5000",
    },
  },
  MLSM1: {
    description: "Excludes property and liability coverage for all dogs on premises",
    formTitle: "Dog Exclusion",
    displayFormName: "ML-SM-1",
    nameEdition: { name: "ML-SM-1", edition: "11-1996" },
    inputs: [],
    liabilityForm: true,
  },
  NHD: {
    automated: true,
    description:
      "Discount is applied to qualified homes at the rate credits shown in the Premium Section of the manual",
    formTitle: "New Home Discount",
    displayFormName: "NHD",
    nameEdition: { name: "NHD", edition: "None" }, // TODO Need pdf
    inputs: [],
  },
  NSD: {
    description:
      "A premium credit may be applied if no members of the household smoke. A statement must be signed by the insured certifying this.",
    formTitle: "Non-Smokers Discount",
    displayFormName: "NSD",
    nameEdition: { name: "ML-379", edition: "01-1987" }, // TODO Need pdf
    inputs: [],
  },
};
