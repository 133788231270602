import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiEmptyPrompt,
  EuiLoadingLogo,
  EuiPanel,
  EuiSelect,
  EuiSpacer,
  EuiTableSortingType,
  EuiTitle,
} from "@elastic/eui";
import useForms from "hooks/queries/useForms";
import { useAPIURL } from "hooks/useAPIURL";
import { useState } from "react";
import pageAndSort from "services/pageAndSort";
import { Form } from "types/apiTypes";

const FormsTable = () => {
  const options = [
    {
      value: "ALL",
      text: "All Forms",
    },
    {
      value: "ML",
      text: "ML Forms - HO, MO and FO Policies",
    },
    {
      value: "FL",
      text: "FL Forms - LP and FI Policies",
    },
    {
      value: "MR",
      text: "MR Forms - Inland Marine",
    },
    {
      value: "SF",
      text: "SF Forms - CP Policies",
    },
    {
      value: "LS",
      text: "LS Forms - Commercial Liability",
    },
    {
      value: "WCIC",
      text: "Miscellaneous Forms",
    },
  ];

  const [value, setValue] = useState(options[1].value);
  const onOptionChange = (e: any) => {
    setValue(e.target.value);
  };

  const { data, isLoading } = useForms(value);
  const defaultURL = useAPIURL();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [showPerPageOptions] = useState(true);
  const [sortField, setSortField] = useState<keyof Form>("name");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  const columns: EuiBasicTableColumn<Form>[] = [
    {
      field: "name",
      name: "Form Name",
    },
    {
      field: "",
      name: "Edition Date",
      render: (form: Form) => {
        return (
          <>
            {form.month}/{form.year}
          </>
        );
      },
    },
    {
      field: "description",
      name: "Description",
      render: (description: string) => {
        return <>{description}</>;
      },
    },
    {
      field: "",
      name: "Actions",
      actions: [
        {
          name: "View",
          description: "View form",
          type: "icon",
          icon: "copy",
          onClick: (form: Form) => {
            window.open(`${defaultURL}/form/${form.name}/${form.month}-${form.year}`, "_blank");
          },
        },
      ],
    },
  ];

  const { pageOfItems, totalItemCount } = pageAndSort(data, { pageIndex, pageSize, sortField, sortDirection });

  const getRowProps = (form: Form) => {
    const { recordID } = form;
    return {
      "data-test-subj": `row=${recordID}`,
      className: "customRowClass",
      // onClick: () => {
      //   console.log("clicked on this: ", form);
      // },
    };
  };

  const getCellProps = (form: Form, column: any) => {
    const { recordID } = form;
    const { field } = column;
    return {
      className: "customCellClass",
      "data-test-subj": `cell-${recordID}-${field}`,
      textOnly: true,
    };
  };

  const sorting: EuiTableSortingType<Form> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
    enableAllColumns: true,
  };

  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    pageSizeOptions: [15, 10, 5],
    hidePerPageOptions: !showPerPageOptions,
  };

  const onTableChange = ({ page, sort }: CriteriaWithPagination<Form>) => {
    const { index: pageIndex, size: pageSize } = page;
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  };

  if (isLoading) {
    return <EuiEmptyPrompt icon={<EuiLoadingLogo logo="filebeatApp" size="xl" />} title={<h2>Loading</h2>} />;
  }
  return (
    <>
      <EuiPanel>
        <EuiSelect options={options} value={value} onChange={onOptionChange} />
      </EuiPanel>
      <EuiSpacer />
      <EuiPanel hasBorder={true}>
        <EuiTitle size="s">
          <h3>Forms</h3>
        </EuiTitle>
        <EuiSpacer size="s" />

        <EuiBasicTable
          style={{ padding: "0 1% 0 1%" }}
          items={pageOfItems}
          itemId="ID"
          columns={columns}
          rowProps={getRowProps}
          cellProps={getCellProps}
          pagination={pagination}
          onChange={onTableChange}
          tableLayout="auto"
          sorting={sorting}
        />
      </EuiPanel>
    </>
  );
};

export default FormsTable;
