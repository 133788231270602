import { EuiFieldText, EuiFieldTextProps } from "@elastic/eui";
import { Controller, useWatch } from "react-hook-form";
import NumberFormat from "react-number-format";

interface IncludedCoverageFieldProps extends EuiFieldTextProps {
  tabItem: number;
  name: string;
  conversion: (coverageA: string, forms: any) => string;
}

const IncludedCoverageField = ({ tabItem, name, conversion, ...rest }: IncludedCoverageFieldProps) => {
  const covA = useWatch({
    name: `locations.${tabItem}.covA` as `${string}`, // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    // defaultValue: "0", // default value before the render
  });
  const forms = useWatch({
    name: `locations.${tabItem}.forms` as `${string}`, // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    // defaultValue: [], // default value before the render
  });
  const { defaultValue, ...reallyRest }: any = { rest };
  return (
    <Controller
      name={`${name}` as const}
      rules={{ required: true }}
      key={tabItem}
      render={({ field }) => {
        const { onChange, value, ref, ...renderRest } = field;
        return (
          <NumberFormat
            customInput={EuiFieldText}
            prepend={
              <NumberFormat
                value={conversion(covA, forms)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                renderText={(value) => (
                  <label className="euiFormLabel euiFormControlLayout__prepend">{value} Included</label>
                )}
              />
            }
            // fullWidth
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={0}
            value={value}
            onValueChange={(target) => {
              onChange(parseInt(target.value, 10));
            }}
            inputRef={ref}
            {...renderRest}
            {...reallyRest}
          />
        );
      }}
    />
  );
};

export default IncludedCoverageField;
